
import { defineComponent } from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import ApiService from "@/core/services/ApiService";
import { DailyRefundsResponse } from "@/modules/payments/interfaces/DailyRefundsResponse";
import DailyRefundsTable from "@/modules/payments/widgets/DailyRefundsTable.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";
import { DateTime } from "luxon";

interface ComponentData {
  loading: boolean;
  error?: unknown;
  page: number;
  response?: DailyRefundsResponse;
  dateRange?: Date[];
}

export default defineComponent({
  name: "DailyRefundsModal",
  components: {
    RequestFailedError,
    LoadingIndicator,
    DailyRefundsTable,
    ModalComponent,
  },
  props: {
    isCancellation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["hide"],
  data(): ComponentData {
    return {
      loading: false,
      error: undefined,
      page: 1,
      response: undefined,
      dateRange: [
        DateTime.now().minus({ days: 30 }).toJSDate(),
        DateTime.now().toJSDate(),
      ],
    };
  },
  computed: {
    headerColumns() {
      return [
        {
          name: "Date",
          key: "date",
          sortable: false,
        },
        {
          name: "Amount",
          key: "amount_eur",
          sortable: false,
        },
        {
          name: "Refunds",
          key: "count",
          sortable: false,
        },
        {
          name: "Stripe",
          key: "stripe",
          sortable: false,
        },
        {
          name: "PayPal",
          key: "paypal",
          sortable: false,
        },
        {
          name: "Sofort",
          key: "sofort",
          sortable: false,
        },
        {
          name: "Adyen",
          key: "adyen",
          sortable: false,
        },
      ];
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    dateRangeChanged() {
      this.page = 1;
      this.loadData();
    },
    pageChanged(page) {
      this.page = page;
      this.loadData();
    },
    resetState(): void {
      this.response = undefined;
      this.loading = true;
      this.error = undefined;
    },
    dateToString(date: Date) {
      return DateTime.fromJSDate(date).toFormat("yyyy-LL-dd");
    },
    collectRequestParams(): Record<string, unknown> {
      return {
        is_cancellation: this.isCancellation ? 1 : 0,
        page: this.page,
        from:
          this.dateRange != null
            ? this.dateToString(this.dateRange[0] as Date)
            : null,
        to:
          this.dateRange != null
            ? this.dateToString(this.dateRange[1] as Date)
            : null,
      };
    },
    loadData(): void {
      this.resetState();
      ApiService.get("payments/widget/refund-stats/daily", {
        params: this.collectRequestParams(),
      })
        .then(({ data }: { data: DailyRefundsResponse }) => {
          this.response = data;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
