import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table-responsive" }

import { PropType } from "vue";
import DailyPerformanceStat from "@/modules/payments/interfaces/DailyPerformanceStat";


export default _defineComponent({
  props: {
  items: {
    type: Array as PropType<DailyPerformanceStat[]>,
    required: true,
  },
},
  setup(__props) {

const props = __props


return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      data: props.items,
      class: "table table-sm table-borderless",
      size: "mini"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "date",
          label: "Days"
        }, {
          default: _withCtx(({ row }) => [
            _createElementVNode("span", {
              class: _normalizeClass({ 'fw-bold': row.date === 'Total' })
            }, _toDisplayString(row.date), 3)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "under_review",
          label: "Requested",
          "class-name": "text-end"
        }, {
          default: _withCtx(({ row }) => [
            _createElementVNode("span", {
              class: _normalizeClass({ 'fw-bold': row.date === 'Total' })
            }, _toDisplayString(_ctx.$filters.currencyEUR(row.under_review)), 3)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "under_review_orders",
          label: "Orders",
          "class-name": "text-end bg-light",
          width: "75"
        }, {
          default: _withCtx(({ row }) => [
            _createElementVNode("span", {
              class: _normalizeClass({ 'fw-bold': row.date === 'Total' })
            }, _toDisplayString(row.under_review_orders), 3)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "approved_pending",
          label: "Approved",
          "class-name": "text-end"
        }, {
          default: _withCtx(({ row }) => [
            _createElementVNode("span", {
              class: _normalizeClass({ 'fw-bold': row.date === 'Total' })
            }, _toDisplayString(_ctx.$filters.currencyEUR(row.approved_pending)), 3)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "approved_pending_orders",
          label: "Orders",
          "class-name": "text-end bg-light",
          width: "75"
        }, {
          default: _withCtx(({ row }) => [
            _createElementVNode("span", {
              class: _normalizeClass({ 'fw-bold': row.date === 'Total' })
            }, _toDisplayString(row.approved_pending_orders), 3)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "total",
          label: "Total",
          "class-name": "text-end"
        }, {
          default: _withCtx(({ row }) => [
            _createElementVNode("span", {
              class: _normalizeClass({ 'fw-bold': row.date === 'Total' })
            }, _toDisplayString(_ctx.$filters.currencyEUR(row.total)), 3)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "total_orders",
          label: "Orders",
          "class-name": "text-end bg-light",
          width: "75"
        }, {
          default: _withCtx(({ row }) => [
            _createElementVNode("span", {
              class: _normalizeClass({ 'fw-bold': row.date === 'Total' })
            }, _toDisplayString(row.total_orders), 3)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}
}

})