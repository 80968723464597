
import { defineComponent, ref } from "vue";
import PaymentRefundResourcePage from "@/modules/payments/views/PaymentRefundsResourcePage.vue";
import PaymentRefundStats from "@/modules/payments/widgets/PaymentRefundStats.vue";

export default defineComponent({
  name: "PaymentRefundsIndexPage",
  components: { PaymentRefundStats, PaymentRefundResourcePage },
  setup() {
    const stats = ref<typeof PaymentRefundStats | null>(null);

    const refreshStats = () => {
      stats.value?.refresh();
    };

    return { refreshStats, stats };
  },
});
