import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { reactive } from "vue";
import DailyPerformanceModal from "@/modules/payments/widgets/DailyPerformanceModal.vue";


export default _defineComponent({
  setup(__props) {

const state = reactive({
  show: false,
});

const toggleModal = (show: boolean) => {
  state.show = show;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "btn btn-sm m-0 px-2 py-0 btn-primary",
      onClick: _cache[0] || (_cache[0] = () => toggleModal(true))
    }, " By Days "),
    (_unref(state).show)
      ? (_openBlock(), _createBlock(DailyPerformanceModal, {
          key: 0,
          onHide: _cache[1] || (_cache[1] = () => toggleModal(false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})