<template>
  <div class="row pb-4">
    <div class="col-12 col-sm-4 col-md-3 col-lg-2">
      <card-component :slim="true" :content-blocked="state.loading">
        <template #header>Requested</template>
        <template v-if="daily" #toolbar>
          <daily-performance-button />
        </template>
        <template v-if="daily" #default>
          <div class="row">
            <div class="col-6">
              <div class="fs-3">
                {{ $filters.currencyEUR(daily.under_review) }}
              </div>
            </div>
            <div class="col-6">
              <refunds-performance-stats
                v-if="performance"
                :stats="performance.under_review"
              />
            </div>
          </div>
        </template>
      </card-component>
    </div>
    <div class="col-12 col-sm-4 col-md-3 col-lg-2 mt-2 mt-sm-0">
      <card-component :slim="true" :content-blocked="state.loading">
        <template #header>Approved</template>
        <template v-if="daily" #toolbar>
          <daily-performance-button />
        </template>
        <template v-if="daily" #default>
          <div class="row">
            <div class="col-6">
              <div class="fs-3">
                {{ $filters.currencyEUR(daily.approved_pending) }}
              </div>
            </div>
            <div class="col-6">
              <refunds-performance-stats
                v-if="performance"
                :stats="performance.approved_pending"
              />
            </div>
          </div>
        </template>
      </card-component>
    </div>
    <div class="col-12 col-sm-4 col-md-3 col-lg-2 mt-2 mt-sm-0">
      <card-component :slim="true" :content-blocked="state.loading">
        <template #header>Today</template>
        <template v-if="daily" #toolbar>
          <span class="pe-2">
            {{
              $filters.currencyEUR(daily.refunded_today + daily.cancelled_today)
            }}
          </span>
        </template>
        <template #default>
          <div v-if="daily != null" class="fs-5">
            <div class="row">
              <div class="col-7">Returns</div>
              <div class="col-5 text-end">
                {{ $filters.currencyEUR(daily.refunded_today) }}
              </div>
            </div>
            <div class="row">
              <div class="col-7">Cancellations</div>
              <div class="col-5 text-end">
                {{ $filters.currencyEUR(daily.cancelled_today) }}
              </div>
            </div>
          </div>
        </template>
      </card-component>
    </div>
    <div class="col-12 col-sm-4 col-md-3 col-lg-2 mt-2 mt-sm-0">
      <card-component :slim="true" :content-blocked="state.loading">
        <template #header>Yesterday</template>
        <template v-if="daily" #toolbar>
          <span class="pe-2">
            {{
              $filters.currencyEUR(
                daily.refunded_yesterday + daily.cancelled_yesterday
              )
            }}
          </span>
        </template>
        <template #default>
          <div v-if="daily != null" class="fs-5">
            <div class="row">
              <div class="col-7">Returns</div>
              <div class="col-5 text-end">
                {{ $filters.currencyEUR(daily.refunded_yesterday) }}
              </div>
            </div>
            <div class="row">
              <div class="col-7">Cancellations</div>
              <div class="col-5 text-end">
                {{ $filters.currencyEUR(daily.cancelled_yesterday) }}
              </div>
            </div>
          </div>
        </template>
      </card-component>
    </div>
    <div class="col-12 col-sm-4 col-md-3 col-lg-2 mt-2 mt-sm-0">
      <card-component :slim="true">
        <template #header>Daily</template>
        <template #default>
          <div class="text-center py-2">
            <daily-refunds-button @click="() => toggleDailyModal(true)">
              Returns
            </daily-refunds-button>
            <daily-refunds-button @click="() => toggleDailyModal(true, true)">
              Cancellations
            </daily-refunds-button>
          </div>
        </template>
      </card-component>
    </div>
    <daily-refunds-modal
      v-if="state.showDailyReturns"
      @hide="() => toggleDailyModal(false)"
    />
    <daily-refunds-modal
      v-if="state.showDailyCancellations"
      :is-cancellation="true"
      @hide="() => toggleDailyModal(false, true)"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import ApiService from "@/core/services/ApiService";
import DailyRefundsButton from "@/modules/payments/widgets/DailyRefundsButton.vue";
import DailyPerformanceButton from "@/modules/payments/widgets/DailyPerformanceButton.vue";
import PerformanceStat from "@/modules/payments/interfaces/PerformanceStat";
import RefundsPerformanceStats from "@/modules/payments/widgets/RefundsPerformanceStats.vue";
import DailyRefundsModal from "@/modules/payments/widgets/DailyRefundsModal.vue";

interface ComponentData {
  loading: boolean;
  response?: StatsResponse;
  showDailyReturns: boolean;
  showDailyCancellations: boolean;
}

interface StatsResponse {
  daily: DailyStats;
  performance: PerformanceStats;
}

interface DailyStats {
  under_review: number;
  approved_pending: number;
  refunded_today: number;
  refunded_yesterday: number;
  cancelled_today: number;
  cancelled_yesterday: number;
}

interface PerformanceStats {
  under_review: PerformanceStat;
  approved_pending: PerformanceStat;
}

export default defineComponent({
  name: "PaymentRefundStats",
  components: {
    DailyRefundsModal,
    RefundsPerformanceStats,
    DailyPerformanceButton,
    DailyRefundsButton,
    CardComponent,
  },
  setup(props, { expose }) {
    const state = reactive<ComponentData>({
      loading: true,
      response: undefined,
      showDailyReturns: false,
      showDailyCancellations: false,
    });

    const loadData = () => {
      state.loading = true;
      ApiService.get("payments/widget/refund-stats")
        .then(({ data }: { data: StatsResponse }) => {
          state.response = data;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const refresh = () => loadData();

    const toggleDailyModal = (show: boolean, isCancellation = false) => {
      if (isCancellation) state.showDailyCancellations = show;
      else state.showDailyReturns = show;
    };

    expose({ refresh });

    onMounted(() => loadData());

    const daily = computed(() => state.response?.daily);
    const performance = computed(() => state.response?.performance);

    return { state, daily, performance, toggleDailyModal };
  },
});
</script>

<style scoped></style>
