<template>
  <button class="btn btn-sm bg-secondary py-1 me-2" @click="$emit('click')">
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DailyRefundsButton",
  emits: ["click"],
});
</script>

<style scoped></style>
