<template>
  <div class="table-responsive">
    <el-table :data="items" class="table table-sm table-borderless" size="mini">
      <el-table-column prop="date" label="Date" />
      <el-table-column prop="amount_eur" label="Total" class-name="text-end">
        <template #default="{ row }">
          {{ $filters.currencyEUR(row.amount_eur) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="total_orders"
        label="Orders"
        class-name="text-end bg-light"
        width="75"
      >
        <template #default="{ row }">
          {{ row.total_orders }}
        </template>
      </el-table-column>
      <el-table-column prop="paypal" label="PayPal" class-name="text-end">
        <template #default="{ row }">
          {{ $filters.currencyEUR(row.paypal) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="paypal_orders"
        label="Orders"
        class-name="text-end bg-light"
        width="75"
      >
        <template #default="{ row }">
          {{ row.paypal_orders }}
        </template>
      </el-table-column>
      <el-table-column prop="stripe" label="Stripe" class-name="text-end">
        <template #default="{ row }">
          {{ $filters.currencyEUR(row.stripe) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="stripe_orders"
        label="Orders"
        class-name="text-end bg-light"
        width="75"
      >
        <template #default="{ row }">
          {{ row.stripe_orders }}
        </template>
      </el-table-column>
      <el-table-column prop="sofort" label="Sofort" class-name="text-end">
        <template #default="{ row }">
          {{ $filters.currencyEUR(row.sofort) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="sofort_orders"
        label="Orders"
        class-name="text-end bg-light"
        width="75"
      >
        <template #default="{ row }">
          {{ row.sofort_orders }}
        </template>
      </el-table-column>
      <el-table-column prop="adyen" label="Adyen" class-name="text-end">
        <template #default="{ row }">
          {{ $filters.currencyEUR(row.adyen) }}
        </template>
      </el-table-column>
      <el-table-column
          prop="adyen_orders"
          label="Orders"
          class-name="text-end bg-light"
          width="75"
      >
        <template #default="{ row }">
          {{ row.adyen_orders }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { DailyRefund } from "@/modules/payments/interfaces/DailyRefundsResponse";

export default defineComponent({
  name: "DailyRefundsTable",
  props: {
    items: {
      type: Array as PropType<DailyRefund[]>,
      required: true,
    },
  },
});
</script>

<style scoped></style>
