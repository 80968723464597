
import { defineComponent, PropType } from "vue";
import { DailyRefund } from "@/modules/payments/interfaces/DailyRefundsResponse";

export default defineComponent({
  name: "DailyRefundsTable",
  props: {
    items: {
      type: Array as PropType<DailyRefund[]>,
      required: true,
    },
  },
});
