
import { computed, defineComponent, onMounted, reactive } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import ApiService from "@/core/services/ApiService";
import DailyRefundsButton from "@/modules/payments/widgets/DailyRefundsButton.vue";
import DailyPerformanceButton from "@/modules/payments/widgets/DailyPerformanceButton.vue";
import PerformanceStat from "@/modules/payments/interfaces/PerformanceStat";
import RefundsPerformanceStats from "@/modules/payments/widgets/RefundsPerformanceStats.vue";
import DailyRefundsModal from "@/modules/payments/widgets/DailyRefundsModal.vue";

interface ComponentData {
  loading: boolean;
  response?: StatsResponse;
  showDailyReturns: boolean;
  showDailyCancellations: boolean;
}

interface StatsResponse {
  daily: DailyStats;
  performance: PerformanceStats;
}

interface DailyStats {
  under_review: number;
  approved_pending: number;
  refunded_today: number;
  refunded_yesterday: number;
  cancelled_today: number;
  cancelled_yesterday: number;
}

interface PerformanceStats {
  under_review: PerformanceStat;
  approved_pending: PerformanceStat;
}

export default defineComponent({
  name: "PaymentRefundStats",
  components: {
    DailyRefundsModal,
    RefundsPerformanceStats,
    DailyPerformanceButton,
    DailyRefundsButton,
    CardComponent,
  },
  setup(props, { expose }) {
    const state = reactive<ComponentData>({
      loading: true,
      response: undefined,
      showDailyReturns: false,
      showDailyCancellations: false,
    });

    const loadData = () => {
      state.loading = true;
      ApiService.get("payments/widget/refund-stats")
        .then(({ data }: { data: StatsResponse }) => {
          state.response = data;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const refresh = () => loadData();

    const toggleDailyModal = (show: boolean, isCancellation = false) => {
      if (isCancellation) state.showDailyCancellations = show;
      else state.showDailyReturns = show;
    };

    expose({ refresh });

    onMounted(() => loadData());

    const daily = computed(() => state.response?.daily);
    const performance = computed(() => state.response?.performance);

    return { state, daily, performance, toggleDailyModal };
  },
});
